import React, { useCallback } from 'react';
import countries from 'countries';
import PropTypes from 'prop-types';

import lang from 'lang';
import { getCookie } from 'utils';

import Input from 'components/common/input/Input';

import useStyles from './styles';

const CountrySelector = ({ newPreferences, setNewPreferences }) => {
  const classes = useStyles();
  const sessionLang = getCookie('session_lang');

  const handleCountryChange = useCallback((newSelectedCountry) => {
    setNewPreferences({
      ...newPreferences,
      country: newSelectedCountry,
    });
  }, [newPreferences, setNewPreferences]);

  const countryItems = countries?.map(country => ({
    value: country.code,
    label: country.name[sessionLang],
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <div className={classes.label}>
        {lang.profile.profilePreferences.country}
      </div>
      <Input
        type="select"
        value={countryItems.find(item => item.value === newPreferences.country)}
        multiple={false}
        options={countryItems}
        onChange={(e) => handleCountryChange(e.value)}
        autoComplete={'country'}
      />
    </div>
  );
};

CountrySelector.propTypes = {
  newPreferences: PropTypes.object,
  setNewPreferences: PropTypes.func,
};

export default CountrySelector;
