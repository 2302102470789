const countries = [
  {
    code: 'AF',
    name: {
      en: 'Afghanistan',
      pt: 'Afeganistão',
      esES: 'Afganistán',
      ptBR: 'Afeganistão',
    },
  },
  {
    code: 'AX',
    name: {
      en: 'Aland Islands',
      pt: 'Ilhas Aland',
      esES: 'Islas Åland',
      ptBR: 'Ilhas Aland',
    },
  },
  {
    code: 'AL',
    name: {
      en: 'Albania',
      pt: 'Albânia',
      esES: 'Albania',
      ptBR: 'Albânia',
    },
  },
  {
    code: 'DZ',
    name: {
      en: 'Algeria',
      pt: 'Argélia',
      esES: 'Argelia',
      ptBR: 'Argélia',
    },
  },
  {
    code: 'AS',
    name: {
      en: 'American Samoa',
      pt: 'Samoa Americana',
      esES: 'Samoa Americana',
      ptBR: 'Samoa Americana',
    },
  },
  {
    code: 'AD',
    name: {
      en: 'Andorra',
      pt: 'Andorra',
      esES: 'Andorra',
      ptBR: 'Andorra',
    },
  },
  {
    code: 'AO',
    name: {
      en: 'Angola',
      pt: 'Angola',
      esES: 'Angola',
      ptBR: 'Angola',
    },
  },
  {
    code: 'AI',
    name: {
      en: 'Anguilla',
      pt: 'Anguila',
      esES: 'Anguila',
      ptBR: 'Anguila',
    },
  },
  {
    code: 'AQ',
    name: {
      en: 'Antarctica',
      pt: 'Antártica',
      esES: 'Antártida',
      ptBR: 'Antártica',
    },
  },
  {
    code: 'AG',
    name: {
      en: 'Antigua and Barbuda',
      pt: 'Antígua e Barbuda',
      esES: 'Antigua y Barbuda',
      ptBR: 'Antígua e Barbuda',
    },
  },
  {
    code: 'AR',
    name: {
      en: 'Argentina',
      pt: 'Argentina',
      esES: 'Argentina',
      ptBR: 'Argentina',
    },
  },
  {
    code: 'AM',
    name: {
      en: 'Armenia',
      pt: 'Armênia',
      esES: 'Armenia',
      ptBR: 'Armênia',
    },
  },
  {
    code: 'AW',
    name: {
      en: 'Aruba',
      pt: 'Aruba',
      esES: 'Aruba',
      ptBR: 'Aruba',
    },
  },
  {
    code: 'AU',
    name: {
      en: 'Australia',
      pt: 'Austrália',
      esES: 'Australia',
      ptBR: 'Austrália',
    },
  },
  {
    code: 'AT',
    name: {
      en: 'Austria',
      pt: 'Áustria',
      esES: 'Austria',
      ptBR: 'Áustria',
    },
  },
  {
    code: 'AZ',
    name: {
      en: 'Azerbaijan',
      pt: 'Azerbaijão',
      esES: 'Azerbaiyán',
      ptBR: 'Azerbaijão',
    },
  },
  {
    code: 'BS',
    name: {
      en: 'Bahamas',
      pt: 'Bahamas',
      esES: 'Bahamas',
      ptBR: 'Bahamas',
    },
  },
  {
    code: 'BH',
    name: {
      en: 'Bahrain',
      pt: 'Bahrein',
      esES: 'Baréin',
      ptBR: 'Bahrein',
    },
  },
  {
    code: 'BD',
    name: {
      en: 'Bangladesh',
      pt: 'Bangladesh',
      esES: 'Bangladés',
      ptBR: 'Bangladesh',
    },
  },
  {
    code: 'BB',
    name: {
      en: 'Barbados',
      pt: 'Barbados',
      esES: 'Barbados',
      ptBR: 'Barbados',
    },
  },
  {
    code: 'BY',
    name: {
      en: 'Belarus',
      pt: 'Bielorrússia',
      esES: 'Bielorrusia',
      ptBR: 'Bielorrússia',
    },
  },
  {
    code: 'BE',
    name: {
      en: 'Belgium',
      pt: 'Bélgica',
      esES: 'Bélgica',
      ptBR: 'Bélgica',
    },
  },
  {
    code: 'BZ',
    name: {
      en: 'Belize',
      pt: 'Belize',
      esES: 'Belice',
      ptBR: 'Belize',
    },
  },
  {
    code: 'BJ',
    name: {
      en: 'Benin',
      pt: 'Benim',
      esES: 'Benín',
      ptBR: 'Benim',
    },
  },
  {
    code: 'BM',
    name: {
      en: 'Bermuda',
      pt: 'Bermudas',
      esES: 'Bermudas',
      ptBR: 'Bermudas',
    },
  },
  {
    code: 'BT',
    name: {
      en: 'Bhutan',
      pt: 'Butão',
      esES: 'Bután',
      ptBR: 'Butão',
    },
  },
  {
    code: 'BO',
    name: {
      en: 'Bolivia',
      pt: 'Bolívia',
      esES: 'Bolivia',
      ptBR: 'Bolívia',
    },
  },
  {
    code: 'BA',
    name: {
      en: 'Bosnia and Herzegovina',
      pt: 'Bósnia e Herzegovina',
      esES: 'Bosnia y Herzegovina',
      ptBR: 'Bósnia e Herzegovina',
    },
  },
  {
    code: 'BW',
    name: {
      en: 'Botswana',
      pt: 'Botsuana',
      esES: 'Botsuana',
      ptBR: 'Botsuana',
    },
  },
  {
    code: 'BV',
    name: {
      en: 'Bouvet Island',
      pt: 'Ilha Bouvet',
      esES: 'Isla Bouvet',
      ptBR: 'Ilha Bouvet',
    },
  },
  {
    code: 'BR',
    sessionLang: 'ptBR',
    name: {
      en: 'Brazil',
      pt: 'Brasil',
      esES: 'Brasil',
      ptBR: 'Brasil',
    },
  },
  {
    code: 'IO',
    name: {
      en: 'British Indian Ocean Territory',
      pt: 'Território Britânico do Oceano Índico',
      esES: 'Territorio Británico del Océano Índico',
      ptBR: 'Território Britânico do Oceano Índico',
    },
  },
  {
    code: 'BN',
    name: {
      en: 'Brunei Darussalam',
      pt: 'Brunei Darussalam',
      esES: 'Brunéi Darussalam',
      ptBR: 'Brunei Darussalam',
    },
  },
  {
    code: 'BG',
    name: {
      en: 'Bulgaria',
      pt: 'Bulgária',
      esES: 'Bulgaria',
      ptBR: 'Bulgária',
    },
  },
  {
    code: 'BF',
    name: {
      en: 'Burkina Faso',
      pt: 'Burquina Faso',
      esES: 'Burkina Faso',
      ptBR: 'Burquina Faso',
    },
  },
  {
    code: 'BI',
    name: {
      en: 'Burundi',
      pt: 'Burundi',
      esES: 'Burundi',
      ptBR: 'Burundi',
    },
  },
  {
    code: 'KH',
    name: {
      en: 'Cambodia',
      pt: 'Camboja',
      esES: 'Camboya',
      ptBR: 'Camboja',
    },
  },
  {
    code: 'CM',
    name: {
      en: 'Cameroon',
      pt: 'Camarões',
      esES: 'Camerún',
      ptBR: 'Camarões',
    },
  },
  {
    code: 'CA',
    name: {
      en: 'Canada',
      pt: 'Canadá',
      esES: 'Canadá',
      ptBR: 'Canadá',
    },
  },
  {
    code: 'CV',
    name: {
      en: 'Cape Verde',
      pt: 'Cabo Verde',
      esES: 'Cabo Verde',
      ptBR: 'Cabo Verde',
    },
  },
  {
    code: 'KY',
    name: {
      en: 'Cayman Islands',
      pt: 'Ilhas Cayman',
      esES: 'Islas Caimán',
      ptBR: 'Ilhas Cayman',
    },
  },
  {
    code: 'CF',
    name: {
      en: 'Central African Republic',
      pt: 'República Centro-Africana',
      esES: 'República Centroafricana',
      ptBR: 'República Centro-Africana',
    },
  },
  {
    code: 'TD',
    name: {
      en: 'Chad',
      pt: 'Chade',
      esES: 'Chad',
      ptBR: 'Chade',
    },
  },
  {
    code: 'CL',
    name: {
      en: 'Chile',
      pt: 'Chile',
      esES: 'Chile',
      ptBR: 'Chile',
    },
  },
  {
    code: 'CN',
    name: {
      en: 'China',
      pt: 'China',
      esES: 'China',
      ptBR: 'China',
    },
  },
  {
    code: 'CX',
    name: {
      en: 'Christmas Island',
      pt: 'Ilha Christmas',
      esES: 'Isla de Navidad',
      ptBR: 'Ilha Christmas',
    },
  },
  {
    code: 'CC',
    name: {
      en: 'Cocos (Keeling) Islands',
      pt: 'Ilhas Cocos (Keeling)',
      esES: 'Islas Cocos (Keeling)',
      ptBR: 'Ilhas Cocos (Keeling)',
    },
  },
  {
    code: 'CO',
    name: {
      en: 'Colombia',
      pt: 'Colômbia',
      esES: 'Colombia',
      ptBR: 'Colômbia',
    },
  },
  {
    code: 'KM',
    name: {
      en: 'Comoros',
      pt: 'Comores',
      esES: 'Comoras',
      ptBR: 'Comores',
    },
  },
  {
    code: 'CG',
    name: {
      en: 'Congo',
      pt: 'Congo',
      esES: 'Congo',
      ptBR: 'Congo',
    },
  },
  {
    code: 'CD',
    name: {
      en: 'Congo, the Democratic Republic of the',
      pt: 'República Democrática do Congo',
      esES: 'República Democrática del Congo',
      ptBR: 'República Democrática do Congo',
    },
  },
  {
    code: 'CK',
    name: {
      en: 'Cook Islands',
      pt: 'Ilhas Cook',
      esES: 'Islas Cook',
      ptBR: 'Ilhas Cook',
    },
  },
  {
    code: 'CR',
    name: {
      en: 'Costa Rica',
      pt: 'Costa Rica',
      esES: 'Costa Rica',
      ptBR: 'Costa Rica',
    },
  },
  {
    code: 'CI',
    name: {
      en: 'Côte d\'Ivoire',
      pt: 'Costa do Marfim',
      esES: 'Costa de Marfil',
      ptBR: 'Costa do Marfim',
    },
  },
  {
    code: 'HR',
    name: {
      en: 'Croatia',
      pt: 'Croácia',
      esES: 'Croacia',
      ptBR: 'Croácia',
    },
  },
  {
    code: 'CU',
    name: {
      en: 'Cuba',
      pt: 'Cuba',
      esES: 'Cuba',
      ptBR: 'Cuba',
    },
  },
  {
    code: 'CY',
    name: {
      en: 'Cyprus',
      pt: 'Chipre',
      esES: 'Chipre',
      ptBR: 'Chipre',
    },
  },
  {
    code: 'CZ',
    name: {
      en: 'Czech Republic',
      pt: 'República Checa',
      esES: 'República Checa',
      ptBR: 'República Checa',
    },
  },
  {
    code: 'DK',
    name: {
      en: 'Denmark',
      pt: 'Dinamarca',
      esES: 'Dinamarca',
      ptBR: 'Dinamarca',
    },
  },
  {
    code: 'DJ',
    name: {
      en: 'Djibouti',
      pt: 'Djibouti',
      esES: 'Yibuti',
      ptBR: 'Djibouti',
    },
  },
  {
    code: 'DM',
    name: {
      en: 'Dominica',
      pt: 'Dominica',
      esES: 'Dominica',
      ptBR: 'Dominica',
    },
  },
  {
    code: 'DO',
    name: {
      en: 'Dominican Republic',
      pt: 'República Dominicana',
      esES: 'República Dominicana',
      ptBR: 'República Dominicana',
    },
  },
  {
    code: 'EC',
    name: {
      en: 'Ecuador',
      pt: 'Equador',
      esES: 'Ecuador',
      ptBR: 'Equador',
    },
  },
  {
    code: 'EG',
    name: {
      en: 'Egypt',
      pt: 'Egito',
      esES: 'Egipto',
      ptBR: 'Egito',
    },
  },
  {
    code: 'SV',
    name: {
      en: 'El Salvador',
      pt: 'El Salvador',
      esES: 'El Salvador',
      ptBR: 'El Salvador',
    },
  },
  {
    code: 'GQ',
    name: {
      en: 'Equatorial Guinea',
      pt: 'Guiné Equatorial',
      esES: 'Guinea Ecuatorial',
      ptBR: 'Guiné Equatorial',
    },
  },
  {
    code: 'ER',
    name: {
      en: 'Eritrea',
      pt: 'Eritreia',
      esES: 'Eritrea',
      ptBR: 'Eritreia',
    },
  },
  {
    code: 'EE',
    name: {
      en: 'Estonia',
      pt: 'Estónia',
      esES: 'Estonia',
      ptBR: 'Estônia',
    },
  },
  {
    code: 'ET',
    name: {
      en: 'Ethiopia',
      pt: 'Etiópia',
      esES: 'Etiopía',
      ptBR: 'Etiópia',
    },
  },
  {
    code: 'FK',
    name: {
      en: 'Falkland Islands (Malvinas)',
      pt: 'Ilhas Falkland (Malvinas)',
      esES: 'Islas Malvinas',
      ptBR: 'Ilhas Falkland (Malvinas)',
    },
  },
  {
    code: 'FO',
    name: {
      en: 'Faroe Islands',
      pt: 'Ilhas Faroé',
      esES: 'Islas Feroe',
      ptBR: 'Ilhas Faroé',
    },
  },
  {
    code: 'FJ',
    name: {
      en: 'Fiji',
      pt: 'Fiji',
      esES: 'Fiyi',
      ptBR: 'Fiji',
    },
  },
  {
    code: 'FI',
    name: {
      en: 'Finland',
      pt: 'Finlândia',
      esES: 'Finlandia',
      ptBR: 'Finlândia',
    },
  },
  {
    code: 'FR',
    name: {
      en: 'France',
      pt: 'França',
      esES: 'Francia',
      ptBR: 'França',
    },
  },
  {
    code: 'GF',
    name: {
      en: 'French Guiana',
      pt: 'Guiana Francesa',
      esES: 'Guayana Francesa',
      ptBR: 'Guiana Francesa',
    },
  },
  {
    code: 'PF',
    name: {
      en: 'French Polynesia',
      pt: 'Polinésia Francesa',
      esES: 'Polinesia Francesa',
      ptBR: 'Polinésia Francesa',
    },
  },
  {
    code: 'TF',
    name: {
      en: 'French Southern Territories',
      pt: 'Territórios Franceses do Sul',
      esES: 'Territorios Australes Franceses',
      ptBR: 'Territórios Franceses do Sul',
    },
  },
  {
    code: 'GA',
    name: {
      en: 'Gabon',
      pt: 'Gabão',
      esES: 'Gabón',
      ptBR: 'Gabão',
    },
  },
  {
    code: 'GM',
    name: {
      en: 'Gambia',
      pt: 'Gâmbia',
      esES: 'Gambia',
      ptBR: 'Gâmbia',
    },
  },
  {
    code: 'GE',
    name: {
      en: 'Georgia',
      pt: 'Geórgia',
      esES: 'Georgia',
      ptBR: 'Geórgia',
    },
  },
  {
    code: 'DE',
    name: {
      en: 'Germany',
      pt: 'Alemanha',
      esES: 'Alemania',
      ptBR: 'Alemanha',
    },
  },
  {
    code: 'GH',
    name: {
      en: 'Ghana',
      pt: 'Gana',
      esES: 'Ghana',
      ptBR: 'Gana',
    },
  },
  {
    code: 'GI',
    name: {
      en: 'Gibraltar',
      pt: 'Gibraltar',
      esES: 'Gibraltar',
      ptBR: 'Gibraltar',
    },
  },
  {
    code: 'GR',
    name: {
      en: 'Greece',
      pt: 'Grécia',
      esES: 'Grecia',
      ptBR: 'Grécia',
    },
  },
  {
    code: 'GL',
    name: {
      en: 'Greenland',
      pt: 'Gronelândia',
      esES: 'Groenlandia',
      ptBR: 'Gronelândia',
    },
  },
  {
    code: 'GD',
    name: {
      en: 'Grenada',
      pt: 'Granada',
      esES: 'Granada',
      ptBR: 'Granada',
    },
  },
  {
    code: 'GP',
    name: {
      en: 'Guadeloupe',
      pt: 'Guadalupe',
      esES: 'Guadalupe',
      ptBR: 'Guadalupe',
    },
  },
  {
    code: 'GU',
    name: {
      en: 'Guam',
      pt: 'Guam',
      esES: 'Guam',
      ptBR: 'Guam',
    },
  },
  {
    code: 'GT',
    name: {
      en: 'Guatemala',
      pt: 'Guatemala',
      esES: 'Guatemala',
      ptBR: 'Guatemala',
    },
  },
  {
    code: 'GG',
    name: {
      en: 'Guernsey',
      pt: 'Guernsey',
      esES: 'Guernsey',
      ptBR: 'Guernsey',
    },
  },
  {
    code: 'GN',
    name: {
      en: 'Guinea',
      pt: 'Guiné',
      esES: 'Guinea',
      ptBR: 'Guiné',
    },
  },
  {
    code: 'GW',
    name: {
      en: 'Guinea-Bissau',
      pt: 'Guiné-Bissau',
      esES: 'Guinea-Bisáu',
      ptBR: 'Guiné-Bissau',
    },
  },
  {
    code: 'GY',
    name: {
      en: 'Guyana',
      pt: 'Guiana',
      esES: 'Guyana',
      ptBR: 'Guiana',
    },
  },
  {
    code: 'HT',
    name: {
      en: 'Haiti',
      pt: 'Haiti',
      esES: 'Haití',
      ptBR: 'Haiti',
    },
  },
  {
    code: 'HM',
    name: {
      en: 'Heard Island and McDonald Islands',
      pt: 'Ilha Heard e Ilhas McDonald',
      esES: 'Islas Heard y McDonald',
      ptBR: 'Ilha Heard e Ilhas McDonald',
    },
  },
  {
    code: 'VA',
    name: {
      en: 'Holy See (Vatican City State)',
      pt: 'Santa Sé (Estado da Cidade do Vaticano)',
      esES: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      ptBR: 'Santa Sé (Estado da Cidade do Vaticano)',
    },
  },
  {
    code: 'HN',
    name: {
      en: 'Honduras',
      pt: 'Honduras',
      esES: 'Honduras',
      ptBR: 'Honduras',
    },
  },
  {
    code: 'HK',
    name: {
      en: 'Hong Kong',
      pt: 'Hong Kong',
      esES: 'Hong Kong',
      ptBR: 'Hong Kong',
    },
  },
  {
    code: 'HU',
    name: {
      en: 'Hungary',
      pt: 'Hungria',
      esES: 'Hungría',
      ptBR: 'Hungria',
    },
  },
  {
    code: 'IS',
    name: {
      en: 'Iceland',
      pt: 'Islândia',
      esES: 'Islandia',
      ptBR: 'Islândia',
    },
  },
  {
    code: 'IN',
    name: {
      en: 'India',
      pt: 'Índia',
      esES: 'India',
      ptBR: 'Índia',
    },
  },
  {
    code: 'ID',
    name: {
      en: 'Indonesia',
      pt: 'Indonésia',
      esES: 'Indonesia',
      ptBR: 'Indonésia',
    },
  },
  {
    code: 'IR',
    name: {
      en: 'Iran, Islamic Republic of',
      pt: 'Irão, República Islâmica do',
      esES: 'Irán, República Islámica de',
      ptBR: 'Irão, República Islâmica do',
    },
  },
  {
    code: 'IQ',
    name: {
      en: 'Iraq',
      pt: 'Iraque',
      esES: 'Irak',
      ptBR: 'Iraque',
    },
  },
  {
    code: 'IE',
    name: {
      en: 'Ireland',
      pt: 'Irlanda',
      esES: 'Irlanda',
      ptBR: 'Irlanda',
    },
  },
  {
    code: 'IM',
    name: {
      en: 'Isle of Man',
      pt: 'Ilha de Man',
      esES: 'Isla de Man',
      ptBR: 'Ilha de Man',
    },
  },
  {
    code: 'IL',
    name: {
      en: 'Israel',
      pt: 'Israel',
      esES: 'Israel',
      ptBR: 'Israel',
    },
  },
  {
    code: 'IT',
    name: {
      en: 'Italy',
      pt: 'Itália',
      esES: 'Italia',
      ptBR: 'Itália',
    },
  },
  {
    code: 'JM',
    name: {
      en: 'Jamaica',
      pt: 'Jamaica',
      esES: 'Jamaica',
      ptBR: 'Jamaica',
    },
  },
  {
    code: 'JP',
    name: {
      en: 'Japan',
      pt: 'Japão',
      esES: 'Japón',
      ptBR: 'Japão',
    },
  },
  {
    code: 'JE',
    name: {
      en: 'Jersey',
      pt: 'Jersey',
      esES: 'Jersey',
      ptBR: 'Jersey',
    },
  },
  {
    code: 'JO',
    name: {
      en: 'Jordan',
      pt: 'Jordânia',
      esES: 'Jordania',
      ptBR: 'Jordânia',
    },
  },
  {
    code: 'KZ',
    name: {
      en: 'Kazakhstan',
      pt: 'Cazaquistão',
      esES: 'Kazajistán',
      ptBR: 'Cazaquistão',
    },
  },
  {
    code: 'KE',
    name: {
      en: 'Kenya',
      pt: 'Quénia',
      esES: 'Kenia',
      ptBR: 'Quénia',
    },
  },
  {
    code: 'KI',
    name: {
      en: 'Kiribati',
      pt: 'Kiribati',
      esES: 'Kiribati',
      ptBR: 'Kiribati',
    },
  },
  {
    code: 'KP',
    name: {
      en: 'Korea, Democratic People\'s Republic of',
      pt: 'Coreia, República Democrática Popular da',
      esES: 'Corea, República Popular Democrática de',
      ptBR: 'Coreia, República Democrática Popular da',
    },
  },
  {
    code: 'KR',
    name: {
      en: 'Korea, Republic of',
      pt: 'Coreia, República da',
      esES: 'Corea, República de',
      ptBR: 'Coreia, República da',
    },
  },
  {
    code: 'KW',
    name: {
      en: 'Kuwait',
      pt: 'Kuwait',
      esES: 'Kuwait',
      ptBR: 'Kuwait',
    },
  },
  {
    code: 'KG',
    name: {
      en: 'Kyrgyzstan',
      pt: 'Quirguistão',
      esES: 'Kirguistán',
      ptBR: 'Quirguistão',
    },
  },
  {
    code: 'LA',
    name: {
      en: 'Lao People\'s Democratic Republic',
      pt: 'Laos, República Democrática Popular do',
      esES: 'República Democrática Popular Lao',
      ptBR: 'Laos, República Democrática Popular do',
    },
  },
  {
    code: 'LV',
    name: {
      en: 'Latvia',
      pt: 'Letónia',
      esES: 'Letonia',
      ptBR: 'Letônia',
    },
  },
  {
    code: 'LB',
    name: {
      en: 'Lebanon',
      pt: 'Líbano',
      esES: 'Líbano',
      ptBR: 'Líbano',
    },
  },
  {
    code: 'LS',
    name: {
      en: 'Lesotho',
      pt: 'Lesoto',
      esES: 'Lesoto',
      ptBR: 'Lesoto',
    },
  },
  {
    code: 'LR',
    name: {
      en: 'Liberia',
      pt: 'Libéria',
      esES: 'Liberia',
      ptBR: 'Libéria',
    },
  },
  {
    code: 'LY',
    name: {
      en: 'Libya',
      pt: 'Líbia',
      esES: 'Libia',
      ptBR: 'Líbia',
    },
  },
  {
    code: 'LI',
    name: {
      en: 'Liechtenstein',
      pt: 'Listenstaine',
      esES: 'Liechtenstein',
      ptBR: 'Listenstaine',
    },
  },
  {
    code: 'LT',
    name: {
      en: 'Lithuania',
      pt: 'Lituânia',
      esES: 'Lituania',
      ptBR: 'Lituânia',
    },
  },
  {
    code: 'LU',
    name: {
      en: 'Luxembourg',
      pt: 'Luxemburgo',
      esES: 'Luxemburgo',
      ptBR: 'Luxemburgo',
    },
  },
  {
    code: 'MO',
    name: {
      en: 'Macao',
      pt: 'Macau',
      esES: 'Macao',
      ptBR: 'Macau',
    },
  },
  {
    code: 'MG',
    name: {
      en: 'Madagascar',
      pt: 'Madagáscar',
      esES: 'Madagascar',
      ptBR: 'Madagáscar',
    },
  },
  {
    code: 'MW',
    name: {
      en: 'Malawi',
      pt: 'Maláui',
      esES: 'Malaui',
      ptBR: 'Maláui',
    },
  },
  {
    code: 'MY',
    name: {
      en: 'Malaysia',
      pt: 'Malásia',
      esES: 'Malasia',
      ptBR: 'Malásia',
    },
  },
  {
    code: 'MV',
    name: {
      en: 'Maldives',
      pt: 'Maldivas',
      esES: 'Maldivas',
      ptBR: 'Maldivas',
    },
  },
  {
    code: 'ML',
    name: {
      en: 'Mali',
      pt: 'Mali',
      esES: 'Malí',
      ptBR: 'Mali',
    },
  },
  {
    code: 'MT',
    name: {
      en: 'Malta',
      pt: 'Malta',
      esES: 'Malta',
      ptBR: 'Malta',
    },
  },
  {
    code: 'MH',
    name: {
      en: 'Marshall Islands',
      pt: 'Ilhas Marshall',
      esES: 'Islas Marshall',
      ptBR: 'Ilhas Marshall',
    },
  },
  {
    code: 'MQ',
    name: {
      en: 'Martinique',
      pt: 'Martinica',
      esES: 'Martinica',
      ptBR: 'Martinica',
    },
  },
  {
    code: 'MR',
    name: {
      en: 'Mauritania',
      pt: 'Mauritânia',
      esES: 'Mauritania',
      ptBR: 'Mauritânia',
    },
  },
  {
    code: 'MU',
    name: {
      en: 'Mauritius',
      pt: 'Maurícia',
      esES: 'Mauricio',
      ptBR: 'Maurícia',
    },
  },
  {
    code: 'YT',
    name: {
      en: 'Mayotte',
      pt: 'Maiote',
      esES: 'Mayotte',
      ptBR: 'Maiote',
    },
  },
  {
    code: 'MX',
    name: {
      en: 'Mexico',
      pt: 'México',
      esES: 'México',
      ptBR: 'México',
    },
  },
  {
    code: 'FM',
    name: {
      en: 'Micronesia, Federated States of',
      pt: 'Micronésia, Estados Federados da',
      esES: 'Micronesia, Estados Federados de',
      ptBR: 'Micronésia, Estados Federados da',
    },
  },
  {
    code: 'MD',
    name: {
      en: 'Moldova, Republic of',
      pt: 'Moldávia, República da',
      esES: 'Moldavia, República de',
      ptBR: 'Moldávia, República da',
    },
  },
  {
    code: 'MC',
    name: {
      en: 'Monaco',
      pt: 'Mónaco',
      esES: 'Mónaco',
      ptBR: 'Mônaco',
    },
  },
  {
    code: 'MN',
    name: {
      en: 'Mongolia',
      pt: 'Mongólia',
      esES: 'Mongolia',
      ptBR: 'Mongólia',
    },
  },
  {
    code: 'ME',
    name: {
      en: 'Montenegro',
      pt: 'Montenegro',
      esES: 'Montenegro',
      ptBR: 'Montenegro',
    },
  },
  {
    code: 'MS',
    name: {
      en: 'Montserrat',
      pt: 'Monserrate',
      esES: 'Montserrat',
      ptBR: 'Monserrate',
    },
  },
  {
    code: 'MA',
    name: {
      en: 'Morocco',
      pt: 'Marrocos',
      esES: 'Marruecos',
      ptBR: 'Marrocos',
    },
  },
  {
    code: 'MZ',
    name: {
      en: 'Mozambique',
      pt: 'Moçambique',
      esES: 'Mozambique',
      ptBR: 'Moçambique',
    },
  },
  {
    code: 'MM',
    name: {
      en: 'Myanmar',
      pt: 'Myanmar',
      esES: 'Myanmar',
      ptBR: 'Myanmar',
    },
  },
  {
    code: 'NA',
    name: {
      en: 'Namibia',
      pt: 'Namíbia',
      esES: 'Namibia',
      ptBR: 'Namíbia',
    },
  },
  {
    code: 'NR',
    name: {
      en: 'Nauru',
      pt: 'Nauru',
      esES: 'Nauru',
      ptBR: 'Nauru',
    },
  },
  {
    code: 'NP',
    name: {
      en: 'Nepal',
      pt: 'Nepal',
      esES: 'Nepal',
      ptBR: 'Nepal',
    },
  },
  {
    code: 'NL',
    name: {
      en: 'Netherlands',
      pt: 'Países Baixos',
      esES: 'Países Bajos',
      ptBR: 'Países Baixos',
    },
  },
  {
    code: 'NC',
    name: {
      en: 'New Caledonia',
      pt: 'Nova Caledónia',
      esES: 'Nueva Caledonia',
      ptBR: 'Nova Caledônia',
    },
  },
  {
    code: 'NZ',
    name: {
      en: 'New Zealand',
      pt: 'Nova Zelândia',
      esES: 'Nueva Zelanda',
      ptBR: 'Nova Zelândia',
    },
  },
  {
    code: 'NI',
    name: {
      en: 'Nicaragua',
      pt: 'Nicarágua',
      esES: 'Nicaragua',
      ptBR: 'Nicarágua',
    },
  },
  {
    code: 'NE',
    name: {
      en: 'Niger',
      pt: 'Níger',
      esES: 'Níger',
      ptBR: 'Níger',
    },
  },
  {
    code: 'NG',
    name: {
      en: 'Nigeria',
      pt: 'Nigéria',
      esES: 'Nigeria',
      ptBR: 'Nigéria',
    },
  },
  {
    code: 'NU',
    name: {
      en: 'Niue',
      pt: 'Niue',
      esES: 'Niue',
      ptBR: 'Niue',
    },
  },
  {
    code: 'NF',
    name: {
      en: 'Norfolk Island',
      pt: 'Ilha Norfolk',
      esES: 'Isla Norfolk',
      ptBR: 'Ilha Norfolk',
    },
  },
  {
    code: 'MP',
    name: {
      en: 'Northern Mariana Islands',
      pt: 'Ilhas Marianas do Norte',
      esES: 'Islas Marianas del Norte',
      ptBR: 'Ilhas Marianas do Norte',
    },
  },
  {
    code: 'NO',
    name: {
      en: 'Norway',
      pt: 'Noruega',
      esES: 'Noruega',
      ptBR: 'Noruega',
    },
  },
  {
    code: 'OM',
    name: {
      en: 'Oman',
      pt: 'Omã',
      esES: 'Omán',
      ptBR: 'Omã',
    },
  },
  {
    code: 'PK',
    name: {
      en: 'Pakistan',
      pt: 'Paquistão',
      esES: 'Pakistán',
      ptBR: 'Paquistão',
    },
  },
  {
    code: 'PW',
    name: {
      en: 'Palau',
      pt: 'Palau',
      esES: 'Palaos',
      ptBR: 'Palau',
    },
  },
  {
    code: 'PS',
    name: {
      en: 'Palestine, State of',
      pt: 'Palestina, Estado da',
      esES: 'Palestina, Estado de',
      ptBR: 'Palestina, Estado da',
    },
  },
  {
    code: 'PA',
    name: {
      en: 'Panama',
      pt: 'Panamá',
      esES: 'Panamá',
      ptBR: 'Panamá',
    },
  },
  {
    code: 'PG',
    name: {
      en: 'Papua New Guinea',
      pt: 'Papua-Nova Guiné',
      esES: 'Papúa Nueva Guinea',
      ptBR: 'Papua-Nova Guiné',
    },
  },
  {
    code: 'PY',
    name: {
      en: 'Paraguay',
      pt: 'Paraguai',
      esES: 'Paraguay',
      ptBR: 'Paraguai',
    },
  },
  {
    code: 'PE',
    name: {
      en: 'Peru',
      pt: 'Peru',
      esES: 'Perú',
      ptBR: 'Peru',
    },
  },
  {
    code: 'PH',
    name: {
      en: 'Philippines',
      pt: 'Filipinas',
      esES: 'Filipinas',
      ptBR: 'Filipinas',
    },
  },
  {
    code: 'PN',
    name: {
      en: 'Pitcairn',
      pt: 'Pitcairn',
      esES: 'Pitcairn',
      ptBR: 'Pitcairn',
    },
  },
  {
    code: 'PL',
    name: {
      en: 'Poland',
      pt: 'Polónia',
      esES: 'Polonia',
      ptBR: 'Polônia',
    },
  },
  {
    code: 'PT',
    sessionLang: 'pt',
    name: {
      en: 'Portugal',
      pt: 'Portugal',
      esES: 'Portugal',
      ptBR: 'Portugal',
    },
  },
  {
    code: 'PR',
    name: {
      en: 'Puerto Rico',
      pt: 'Porto Rico',
      esES: 'Puerto Rico',
      ptBR: 'Porto Rico',
    },
  },
  {
    code: 'QA',
    name: {
      en: 'Qatar',
      pt: 'Catar',
      esES: 'Catar',
      ptBR: 'Catar',
    },
  },
  {
    code: 'RE',
    name: {
      en: 'Réunion',
      pt: 'Reunião',
      esES: 'Reunión',
      ptBR: 'Reunião',
    },
  },
  {
    code: 'RO',
    name: {
      en: 'Romania',
      pt: 'Roménia',
      esES: 'Rumanía',
      ptBR: 'Romênia',
    },
  },
  {
    code: 'RU',
    name: {
      en: 'Russian Federation',
      pt: 'Federação Russa',
      esES: 'Federación Rusa',
      ptBR: 'Federação Russa',
    },
  },
  {
    code: 'RW',
    name: {
      en: 'Rwanda',
      pt: 'Ruanda',
      esES: 'Ruanda',
      ptBR: 'Ruanda',
    },
  },
  {
    code: 'BL',
    name: {
      en: 'Saint Barthélemy',
      pt: 'São Bartolomeu',
      esES: 'San Bartolomé',
      ptBR: 'São Bartolomeu',
    },
  },
  {
    code: 'SH',
    name: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      pt: 'Santa Helena, Ascensão e Tristão da Cunha',
      esES: 'Santa Elena, Ascensión y Tristán de Acuña',
      ptBR: 'Santa Helena, Ascensão e Tristão da Cunha',
    },
  },
  {
    code: 'KN',
    name: {
      en: 'Saint Kitts and Nevis',
      pt: 'São Cristóvão e Nevis',
      esES: 'San Cristóbal y Nieves',
      ptBR: 'São Cristóvão e Nevis',
    },
  },
  {
    code: 'LC',
    name: {
      en: 'Saint Lucia',
      pt: 'Santa Lúcia',
      esES: 'Santa Lucía',
      ptBR: 'Santa Lúcia',
    },
  },
  {
    code: 'MF',
    name: {
      en: 'Saint Martin (French part)',
      pt: 'São Martinho (parte francesa)',
      esES: 'San Martín (parte francesa)',
      ptBR: 'São Martinho (parte francesa)',
    },
  },
  {
    code: 'PM',
    name: {
      en: 'Saint Pierre and Miquelon',
      pt: 'São Pedro e Miquelon',
      esES: 'San Pedro y Miquelón',
      ptBR: 'São Pedro e Miquelon',
    },
  },
  {
    code: 'VC',
    name: {
      en: 'Saint Vincent and the Grenadines',
      pt: 'São Vicente e Granadinas',
      esES: 'San Vicente y las Granadinas',
      ptBR: 'São Vicente e Granadinas',
    },
  },
  {
    code: 'WS',
    name: {
      en: 'Samoa',
      pt: 'Samoa',
      esES: 'Samoa',
      ptBR: 'Samoa',
    },
  },
  {
    code: 'SM',
    name: {
      en: 'San Marino',
      pt: 'San Marino',
      esES: 'San Marino',
      ptBR: 'San Marino',
    },
  },
  {
    code: 'ST',
    name: {
      en: 'Sao Tome and Principe',
      pt: 'São Tomé e Príncipe',
      esES: 'Santo Tomé y Príncipe',
      ptBR: 'São Tomé e Príncipe',
    },
  },
  {
    code: 'SA',
    name: {
      en: 'Saudi Arabia',
      pt: 'Arábia Saudita',
      esES: 'Arabia Saudita',
      ptBR: 'Arábia Saudita',
    },
  },
  {
    code: 'SN',
    name: {
      en: 'Senegal',
      pt: 'Senegal',
      esES: 'Senegal',
      ptBR: 'Senegal',
    },
  },
  {
    code: 'RS',
    name: {
      en: 'Serbia',
      pt: 'Sérvia',
      esES: 'Serbia',
      ptBR: 'Sérvia',
    },
  },
  {
    code: 'SC',
    name: {
      en: 'Seychelles',
      pt: 'Seicheles',
      esES: 'Seychelles',
      ptBR: 'Seicheles',
    },
  },
  {
    code: 'SL',
    name: {
      en: 'Sierra Leone',
      pt: 'Serra Leoa',
      esES: 'Sierra Leona',
      ptBR: 'Serra Leoa',
    },
  },
  {
    code: 'SG',
    name: {
      en: 'Singapore',
      pt: 'Cingapura',
      esES: 'Singapur',
      ptBR: 'Cingapura',
    },
  },
  {
    code: 'SX',
    name: {
      en: 'Sint Maarten (Dutch part)',
      pt: 'Sint Maarten (parte holandesa)',
      esES: 'Sint Maarten (parte holandesa)',
      ptBR: 'Sint Maarten (parte holandesa)',
    },
  },
  {
    code: 'SK',
    name: {
      en: 'Slovakia',
      pt: 'Eslováquia',
      esES: 'Eslovaquia',
      ptBR: 'Eslováquia',
    },
  },
  {
    code: 'SI',
    name: {
      en: 'Slovenia',
      pt: 'Eslovénia',
      esES: 'Eslovenia',
      ptBR: 'Eslovênia',
    },
  },
  {
    code: 'SB',
    name: {
      en: 'Solomon Islands',
      pt: 'Ilhas Salomão',
      esES: 'Islas Salomón',
      ptBR: 'Ilhas Salomão',
    },
  },
  {
    code: 'SO',
    name: {
      en: 'Somalia',
      pt: 'Somália',
      esES: 'Somalia',
      ptBR: 'Somália',
    },
  },
  {
    code: 'ZA',
    name: {
      en: 'South Africa',
      pt: 'África do Sul',
      esES: 'Sudáfrica',
      ptBR: 'África do Sul',
    },
  },
  {
    code: 'GS',
    name: {
      en: 'South Georgia and the South Sandwich Islands',
      pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      esES: 'Georgia del Sur y las Islas Sandwich del Sur',
      ptBR: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    },
  },
  {
    code: 'SS',
    name: {
      en: 'South Sudan',
      pt: 'Sudão do Sul',
      esES: 'Sudán del Sur',
      ptBR: 'Sudão do Sul',
    },
  },
  {
    code: 'ES',
    sessionLang: 'esES',
    name: {
      en: 'Spain',
      pt: 'Espanha',
      esES: 'España',
      ptBR: 'Espanha',
    },
  },
  {
    code: 'LK',
    name: {
      en: 'Sri Lanka',
      pt: 'Sri Lanka',
      esES: 'Sri Lanka',
      ptBR: 'Sri Lanka',
    },
  },
  {
    code: 'SD',
    name: {
      en: 'Sudan',
      pt: 'Sudão',
      esES: 'Sudán',
      ptBR: 'Sudão',
    },
  },
  {
    code: 'SR',
    name: {
      en: 'Suriname',
      pt: 'Suriname',
      esES: 'Surinam',
      ptBR: 'Suriname',
    },
  },
  {
    code: 'SJ',
    name: {
      en: 'Svalbard and Jan Mayen',
      pt: 'Svalbard e Jan Mayen',
      esES: 'Svalbard y Jan Mayen',
      ptBR: 'Svalbard e Jan Mayen',
    },
  },
  {
    code: 'SZ',
    name: {
      en: 'Swaziland',
      pt: 'Suazilândia',
      esES: 'Suazilandia',
      ptBR: 'Suazilândia',
    },
  },
  {
    code: 'SE',
    name: {
      en: 'Sweden',
      pt: 'Suécia',
      esES: 'Suecia',
      ptBR: 'Suécia',
    },
  },
  {
    code: 'CH',
    name: {
      en: 'Switzerland',
      pt: 'Suíça',
      esES: 'Suiza',
      ptBR: 'Suíça',
    },
  },
  {
    code: 'SY',
    name: {
      en: 'Syrian Arab Republic',
      pt: 'República Árabe Síria',
      esES: 'República Árabe Siria',
      ptBR: 'República Árabe Síria',
    },
  },
  {
    code: 'TW',
    name: {
      en: 'Taiwan, Province of China',
      pt: 'Taiwan, Província da China',
      esES: 'Taiwán, Provincia de China',
      ptBR: 'Taiwan, Província da China',
    },
  },
  {
    code: 'TJ',
    name: {
      en: 'Tajikistan',
      pt: 'Tajiquistão',
      esES: 'Tayikistán',
      ptBR: 'Tajiquistão',
    },
  },
  {
    code: 'TZ',
    name: {
      en: 'Tanzania, United Republic of',
      pt: 'Tanzânia, República Unida da',
      esES: 'Tanzania, República Unida de',
      ptBR: 'Tanzânia, República Unida da',
    },
  },
  {
    code: 'TH',
    name: {
      en: 'Thailand',
      pt: 'Tailândia',
      esES: 'Tailandia',
      ptBR: 'Tailândia',
    },
  },
  {
    code: 'TG',
    name: {
      en: 'Togo',
      pt: 'Togo',
      esES: 'Togo',
      ptBR: 'Togo',
    },
  },
  {
    code: 'TK',
    name: {
      en: 'Tokelau',
      pt: 'Tokelau',
      esES: 'Tokelau',
      ptBR: 'Tokelau',
    },
  },
  {
    code: 'TO',
    name: {
      en: 'Tonga',
      pt: 'Tonga',
      esES: 'Tonga',
      ptBR: 'Tonga',
    },
  },
  {
    code: 'TT',
    name: {
      en: 'Trinidad and Tobago',
      pt: 'Trinidad e Tobago',
      esES: 'Trinidad y Tobago',
      ptBR: 'Trinidad e Tobago',
    },
  },
  {
    code: 'TN',
    name: {
      en: 'Tunisia',
      pt: 'Tunísia',
      esES: 'Túnez',
      ptBR: 'Tunísia',
    },
  },
  {
    code: 'TR',
    name: {
      en: 'Turkey',
      pt: 'Turquia',
      esES: 'Turquía',
      ptBR: 'Turquia',
    },
  },
  {
    code: 'TM',
    name: {
      en: 'Turkmenistan',
      pt: 'Turcomenistão',
      esES: 'Turkmenistán',
      ptBR: 'Turcomenistão',
    },
  },
  {
    code: 'TC',
    name: {
      en: 'Turks and Caicos Islands',
      pt: 'Ilhas Turcas e Caicos',
      esES: 'Islas Turcas y Caicos',
      ptBR: 'Ilhas Turcas e Caicos',
    },
  },
  {
    code: 'TV',
    name: {
      en: 'Tuvalu',
      pt: 'Tuvalu',
      esES: 'Tuvalu',
      ptBR: 'Tuvalu',
    },
  },
  {
    code: 'UG',
    name: {
      en: 'Uganda',
      pt: 'Uganda',
      esES: 'Uganda',
      ptBR: 'Uganda',
    },
  },
  {
    code: 'UA',
    name: {
      en: 'Ukraine',
      pt: 'Ucrânia',
      esES: 'Ucrania',
      ptBR: 'Ucrânia',
    },
  },
  {
    code: 'AE',
    name: {
      en: 'United Arab Emirates',
      pt: 'Emirados Árabes Unidos',
      esES: 'Emiratos Árabes Unidos',
      ptBR: 'Emirados Árabes Unidos',
    },
  },
  {
    code: 'GB',
    sessionLang: 'en',
    name: {
      en: 'United Kingdom',
      pt: 'Reino Unido',
      esES: 'Reino Unido',
      ptBR: 'Reino Unido',
    },
  },
  {
    code: 'US',
    name: {
      en: 'United States of America',
      pt: 'Estados Unidos da América',
      esES: 'Estados Unidos de América',
      ptBR: 'Estados Unidos da América',
    },
  },
  {
    code: 'UY',
    name: {
      en: 'Uruguay',
      pt: 'Uruguai',
      esES: 'Uruguay',
      ptBR: 'Uruguai',
    },
  },
  {
    code: 'UZ',
    name: {
      en: 'Uzbekistan',
      pt: 'Uzbequistão',
      esES: 'Uzbekistán',
      ptBR: 'Uzbequistão',
    },
  },
  {
    code: 'VU',
    name: {
      en: 'Vanuatu',
      pt: 'Vanuatu',
      esES: 'Vanuatu',
      ptBR: 'Vanuatu',
    },
  },
  {
    code: 'VE',
    name: {
      en: 'Venezuela, Bolivarian Republic of',
      pt: 'Venezuela, República Bolivariana da',
      esES: 'Venezuela, República Bolivariana de',
      ptBR: 'Venezuela, República Bolivariana da',
    },
  },
  {
    code: 'VN',
    name: {
      en: 'Viet Nam',
      pt: 'Vietname',
      esES: 'Vietnam',
      ptBR: 'Vietnã',
    },
  },
  {
    code: 'WF',
    name: {
      en: 'Wallis and Futuna',
      pt: 'Wallis e Futuna',
      esES: 'Wallis y Futuna',
      ptBR: 'Wallis e Futuna',
    },
  },
  {
    code: 'EH',
    name: {
      en: 'Western Sahara',
      pt: 'Saara Ocidental',
      esES: 'Sahara Occidental',
      ptBR: 'Saara Ocidental',
    },
  },
  {
    code: 'YE',
    name: {
      en: 'Yemen',
      pt: 'Iémen',
      esES: 'Yemen',
      ptBR: 'Iêmen',
    },
  },
  {
    code: 'ZM',
    name: {
      en: 'Zambia',
      pt: 'Zâmbia',
      esES: 'Zambia',
      ptBR: 'Zâmbia',
    },
  },
  {
    code: 'ZW',
    name: {
      en: 'Zimbabwe',
      pt: 'Zimbábue',
      esES: 'Zimbabue',
      ptBR: 'Zimbábue',
    },
  },
];
export default countries;
