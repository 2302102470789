import React, { useState } from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import { Button, Dropdown } from 'antd';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { EN, PT, ES, BR, selectUserLang } from 'actions/userActions';
import api from 'api';
import { SESSION_LANG } from 'constants/cookies';
import useApi from 'hooks/useApi';
import lang from 'lang';

import Tooltip from '../Tooltip';

import useStyles from './styles';

const LangSelector = ({ tipDirection }) => {
  const classes = useStyles();
  const locale = useSelector(selectUserLang);
  const validationStatus = locale;
  const [updateLangRequest] = useApi(api.updateLang);
  const toast = useToast();
  const loggedIn = useSelector(state => state.user.loggedIn);

  const [loading, setLoading] = useState(false);

  const actions = [
    {
      key: EN,
      label: lang.en,
      value: 'EN',
    },
    {
      key: ES,
      label: lang.esES,
      value: 'ES',
    },
    {
      key: PT,
      label: lang.pt,
      value: 'PT',
    },
    {
      key: BR,
      label: lang.ptBR,
      value: 'PT (BR)',
    },
  ];

  const handleChange = ({ key }) => {
    const newLang = key;
    Cookies.set(SESSION_LANG, newLang, { domain: process.env.REACT_APP_INTUITIVO_DOMAIN, expires: new Date('9999-12-31') });
    setLoading(true);
    updateLangRequest([], { newLang }, ({ data }) => {
      if (data.status !== 0 && loggedIn) {
        setLoading(false);
        toast.error(lang.oops);
        return;
      }

      window.location.reload();
    });
  };

  const onItemClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    handleChange({ key });
  };

  return (
    <Tooltip
      tip={lang.changeLang}
      place={tipDirection}
    >
      <div className={classes.langToggler}>
        <Dropdown
          menu={{ items: actions, onClick: onItemClick }}
          trigger="click"
          disabled={loading}
        >
          <Button className={classes.dropdownButton} icon={<FontAwesomeIcon icon={faLanguage} />}>
            {actions.find(action => action.key === validationStatus)?.value}
          </Button>
        </Dropdown>
      </div>
    </Tooltip>
  );
};

LangSelector.propTypes = {
  tipDirection: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};

export default LangSelector;
